@font-face {
  font-family: 'Montserrat Bold';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Medium';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/Montserrat-Medium.ttf') format('truetype');
}
/* MA Style Variables */
@import '../colors';

$primary:     #215486;
$secondary:   darken(#dda037, 10%) !default;
$tertiary:    #344e37;
$ticker-bg:   #3A95D2;
$quaternary:  #672021;
$light:       #e6e6e6;
$green:       #30864f;

$state-theme-colors: (
  "tertiary": $tertiary,
  "quaternary": $quaternary,
  "green": $green,
  "danger": darken(#FF7D7D, 36%),
  "info":   darken(#ecf6fb, 20%)
);

$theme-colors: map-merge($theme-colors, $state-theme-colors);

$font-family-base: 'Montserrat Regular', sans-serif;
$headings-font-family: 'Montserrat Bold', serif;
$font-size-base: 1rem;

/* Primary Page-Styling Variables */
$body-bg:                           #555;
$body-color:                        #222;

//Process Page Styling
$process-page-themes: (
  "claim": $tertiary,
  "holder": $quaternary
) !default;

$custom-bg-variants: (
  "ticker": $ticker-bg
);

$link-color:                        darken(#0d94e2, 12%) !default;

$btn-action-bg:                     $tertiary;
$btn-pop-bg:                        $secondary;

$dropdown-bg:                       #4d4d4d;
$dropdown-hover-bg:                 none;
$dropdown-divider-bg:               #777;

$dropdown-link-color:               $light;
$dropdown-link-hover-color:         $white;
$dropdown-link-hover-bg:            #292929;

$mobile-navbar-bg:                  #555;
$static-page-subtitle-color:        #222;

/* Image Size & Image Source Variables */
$banner-image-url:                  url('/images/banner.jpg');
$body-background-image-url:         url('/images/bg_texture.png');

$claim-header-logo-url:             url('/images/ma_logo_compact.png');
$claim-header-logo-width:           190px;
$claim-header-logo-height:          75px;

$holder-header-logo-url:            $claim-header-logo-url;
$holder-header-logo-width:          $claim-header-logo-width;
$holder-header-logo-height:         $claim-header-logo-height;

$home-logo-url:                     url('/images/ma_logo.png');
$home-logo-width:                   420px;
$home-logo-height:                  80px;
$home-logo-resize-value-map:        (width: 80px,
                                     height: $home-logo-height,
                                     background-image: $home-logo-url);

$treasurer-logo-url:                $home-logo-url;
$treasurer-logo-width:              $home-logo-width;
$treasurer-logo-height:             $home-logo-height;
$treasurer-logo-resize-value-map:   $home-logo-resize-value-map;

$footer-logo-url:                   url('/images/ma_seal.png');
$footer-logo-width:                 135px;
$footer-logo-height:                135px;

$claimform-image-url:               url('/images/CDU_example.png');

$icon-email-url:                    none;
$icon-facebook-url:                 url('/images/facebook.png');
$icon-instagram-url:                none;
$icon-twitter-url:                  url('/images/twitter.png');
$icon-youtube-url:                  none;
$icon-linkedin-url:                 none;

$social-icon-width:                 50px;
$social-icon-height:                50px;
$jumbotron-bg:                      rgba(255, 255, 255, 0.5);

.home-page > .home-banner {
  .card {
    h1, h3{
      font-family: $font-family-base !important;
    }
    .form-control{
      color: black;
    }
    > .card-img {
      min-height: 800px !important;
    }
  }
}

.text-red {
  color: $red;
}

.f9ChatMinimizedButton {
  overflow: visible;
}

.f9ChatWidget {
  bottom: 47px !important;
 }

 @media only screen and (max-width: 576px) {
  .f9ChatWidget {
    left: 0;
  }
  .f9ChatOpened {
    padding-top: 45px;
  }
}

#launchButton {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

#launchButton img {
  width: 75% !important;
  height: auto !important;
}
